import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "../components/utils"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Container = styled.div`
  box-sizing: content-box;
  margin: 0 auto;
  max-width: ${rem(1080)};
`

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: page not found" />
      <Container>
        <p>
          <strong>Sorry, page not found.</strong>{" "}
          <span role="img" aria-label="disappointed">
            😞
          </span>
        </p>
        <Link to="/">Return to homepage</Link>
      </Container>
    </Layout>
  )
}
